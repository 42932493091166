import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";
export default function Slick({
  children,
  slidesToScroll = 1,
  slidesToShow = 3,
  dots = false,
  infinite = true,
  responsive,
  navigation = true,
  fade = false,
  autoplay = true,
  onSlideChange,
}) {
  let nextButtonClassName =
    "w-8 h-8 text-base lg:text-lg text-white flex items-center justify-center rounded-full bg-lightBlue shrink-0 transition duration-250 hover:bg-gray-300 hover:text-white focus:outline-none transform shadow-navigation";

  let prevButtonClassName =
    "w-8 h-8 text-base lg:text-lg text-white flex items-center justify-center rounded-full bg-lightBlue shrink-0 transition duration-250 hover:bg-gray-300 hover:text-white focus:outline-none transform shadow-navigation";

  const NextButton = ({ onClick }) => {
    return (
      <button
        // ref={nextRef}
        onClick={onClick}
        className={nextButtonClassName}
        aria-label="next-button"
      >
        <div className="w-5 h-5 relative transition duration-300 ease-in-out transform group-hover:-rotate-180">
          <Image
            src={"/images/icons/chevron-right.svg"}
            alt={"email icon"}
            fill
            className="chevron object-contain object-left"
            quality={100}
            priority={true}
            sizes="(max-width: 479px) 100px,(max-width: 768px) 100px, (max-width: 1200px) 100px,(max-width: 1920px) 100px, 100px"
          />
        </div>
        {/* <IoIosArrowForward /> */}
      </button>
    );
  };
  const PrevButton = ({ onClick }) => {
    return (
      <button
        // ref={prevRef}
        className={prevButtonClassName}
        aria-label="prev-button"
        onClick={onClick}
      >
        <div className="w-5 h-5 relative transition duration-300 ease-in-out transform group-hover:-rotate-180">
          <Image
            src={"/images/icons/chevron-left.svg"}
            alt={"email icon"}
            fill
            className="chevron object-contain object-left"
            quality={100}
            priority={true}
            sizes="(max-width: 479px) 100px,(max-width: 768px) 100px, (max-width: 1200px) 100px,(max-width: 1920px) 100px, 100px"
          />
        </div>
      </button>
    );
  };
  const [slideIndex, setSlideIndex] = useState(0);
  var settings = {
    dots: dots,
    infinite: infinite,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    nextArrow: navigation ? <NextButton /> : false,
    prevArrow: navigation ? <PrevButton /> : false,
    responsive: responsive,
    lazyLoad: true,
    dotsClass: "slick-dots slick-thumb",
    swipeToSlide: true,
    fade: fade,
    autoplay: autoplay,
    autoplaySpeed: 6000,
    draggable: true,
    accessibility: true,
    beforeChange: (current, next) => {
      setSlideIndex(next);
      if (onSlideChange) onSlideChange(next); // Call the callback here
    },
    // cssEase: "linear",
  };

  return (
    <div className="w-full">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}
