import React from "react";
import Link from "next/link";
import cn from "classnames";
export default function KnowMore({
  text,
  path,
  variant = "blue",
  className,
  external = false,
  ...props
}) {
  return (
    <Link
      href={path}
      target={external ? "_blank" : "_self"}
      className={cn(
        {
          "text-white hover:text-blue bg-blue hover:bg-white":
            variant == "blue",
          "text-white hover:text-blue hover:bg-white bg-lightBlue":
            variant == "lightBlue",
          "text-white hover:text-blue bg-blue hover:bg-white border-[1px] border-blue":
            variant == "darkBlue",
        },
        className,
        ` font-medium md:text-[14px] text-sm px-6 py-2 rounded-full mt-3 capitalize transition-all duration-300 ease w-fit`
      )}
      {...props}
    >
      {text}
    </Link>
  );
}
