export const logisticsData = [
  {
    title: "Date",
    description: "September 25th, 2024",
    icon: "/images/icons/calendar-white.png",
  },
  {
    title: "Time",
    description: "2.00pm to 4.30pm ET",
    icon: "/images/icons/clock-white.png",
  },
  {
    title: "Location",
    description:
      "Jay Conference Bryant Park, 109 W 39th St 2nd Fl, New York, NY 10018, United States",
    icon: "/images/icons/location-white.png",
  },
];
