import React from "react";
import Image from "next/image";
import cn from "classnames";
import KnowMore from "./know-more";
import { logisticsData } from "@/data/logistics";
import { useRouter } from "next/router";
import { m } from "framer-motion";
export default function BannerCard({
  banner,
  className,
  variant = "normal",
  isActive = false,
  description,
  anchor,
}) {
  const router = useRouter();
  return (
    <div className={cn("mx-auto w-full", className)}>
      <div
        className={cn(
          "w-full group flex justify-center relative overflow-hidden",
          {
            "lg:h-[45vw] md:h-[60vh] h-[590px]": variant === "normal",
            "md:h-[40vw] h-[350px]": variant === "single",
            "md:h-[20vw] h-[150px]": variant === "mini",
          }
        )}
      >
        <Image
          src={banner?.image}
          alt={banner?.image}
          fill
          className="object-cover md:object-[50%_20%] object-[70%_50%]"
          loading={isActive ? "eager" : "lazy"}
          priority={isActive ? true : false}
          sizes="(max-width: 479px) 479px,(max-width: 768px) 768px, (max-width: 1200px) 1200px,(max-width: 1920px) 1920px, 1000px"
        />
        {variant === "normal" && (
          <div className="bg-black/20 absolute top-0 left-0 w-full h-full flex flex-col items-start justify-end lg:px-[72px] md:px-12 px-6 py-6">
            <m.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 100, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="w-full flex flex-col items-start justify-end gap-4 lg:max-w-[850px]"
            >
              <h1 className="text-white leading-tight">{banner?.title}</h1>
              <div className="h-fit w-full text-black font-medium">
                <div
                  dangerouslySetInnerHTML={{ __html: banner?.description }}
                  className="text-inherit md:text-base lg:text-lg text-sm text-white"
                ></div>
                {router?.pathname === "/" && (
                  <div className="grid md:grid-cols-3 grid-cols-1 auto-rows-auto lg:gap-8 gap-4 mt-4 lg:mb-6 mb-8">
                    {logisticsData?.map((row, key) => (
                      <div
                        className="flex flex-row lg:items-center gap-4"
                        key={"logistic" + key}
                      >
                        <div className="relative lg:h-[30px] lg:w-[30px] h-[20px] w-[20px] shrink-0">
                          <Image
                            src={row?.icon}
                            alt={row?.title}
                            fill
                            className="object-contain object-center w-full"
                            sizes="(max-width: 479px) 479px,(max-width: 768px) 768px, (max-width: 1200px) 1200px,(max-width: 1920px) 1920px, 1000px"
                          />
                        </div>
                        <div className="flex flex-col items-start justify-start gap-1">
                          <div
                            className="text-[14px] !leading-tight font-normal text-white [&>a]:text-white"
                            dangerouslySetInnerHTML={{
                              __html: `${row?.description}`,
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <KnowMore
                  text={banner?.buttonText ?? "Read More"}
                  path={banner?.buttonLink ?? "#"}
                  variant="lightBlue"
                />
              </div>
            </m.div>
          </div>
        )}
        {variant === "single" && (
          <div className="bg-black/20 absolute top-0 left-0 w-full h-full flex flex-col items-start justify-end lg:px-[72px] md:px-12 px-4 lg:py-12 py-6">
            <m.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 100, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="w-fit flex flex-col items-start justify-end gap-4"
            >
              <h1 className="h-fit !leading-tight mb-0 pb-0 w-fit text-white font-semibold">
                {banner?.title}
              </h1>
              {description && (
                <p className="h-fit lg:text-lg md:text-base text-sm text-white font-medium w-full max-w-[600px] pb-5">
                  {description}
                </p>
              )}
              {anchor && <div id={anchor} />}
            </m.div>
          </div>
        )}
      </div>
    </div>
  );
}
