import React from "react";
import { NextSeo } from "next-seo";
export default function Seo({ seo }) {
  const {
    slug,
    metaTitle = "People’s Courage International | Non-Profit Charity Organizations in USA",
    metaDescription = "People’s Courage International is a USA-based not-for-profit organization. Join our charity and help us provide critical support to those in need around the world.",
    metaImage = "https://peoplescourageinternational.org/images/pci-logo-512x512.webp",
  } = seo;
  return (
    <NextSeo
      title={metaTitle}
      description={metaDescription}
      url={`https://peoplescourageinternational.org/${slug}`}
      openGraph={{
        type: "website",
        locale: "en_IE",
        site_name: "People’s Courage International",
        url: `https://peoplescourageinternational.org/${slug}`,
        title: metaTitle,
        description: metaDescription,
        images: [
          {
            url: metaImage,
            width: 140,
            height: 140,
            alt: "People’s Courage International",
          },
        ],
      }}
    />
  );
}
